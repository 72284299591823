var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"fab":"","dark":"","x-small":"","color":"grey darken-3","to":{
                name: "contratantes.cpts",
                params: { id: _vm.contractor_id },
              }}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-arrow-left')}})],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Atrás')}})]),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(_vm.$route.meta.title)}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})])],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"data_form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('h2',{staticClass:"text-caption",domProps:{"textContent":_vm._s('GENERAL')}})]),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Procedimiento definitivo (CPT)","items":_vm.cpts,"item-value":"id","item-text":function (v) { return ((v.code) + " | " + (v.cpt)); },"loading":_vm.cpts_ldg,"rules":_vm.rules.required,"dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-caption d-flex flex-column"},[_vm._v(" "+_vm._s(((item.code) + " | " + (item.cpt)))+" ")])]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-caption d-flex flex-column"},[_vm._v(" "+_vm._s(((item.code) + " | " + (item.cpt)))+" ")])]}}]),model:{value:(_vm.data.cpt_id),callback:function ($$v) {_vm.$set(_vm.data, "cpt_id", $$v)},expression:"data.cpt_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dense":"","label":"Observación","rows":"2"},model:{value:(_vm.data.observation),callback:function ($$v) {_vm.$set(_vm.data, "observation", $$v)},expression:"data.observation"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Monto","type":"number","dense":""},model:{value:(_vm.data.amount),callback:function ($$v) {_vm.$set(_vm.data, "amount", $$v)},expression:"data.amount"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":'Exclusión'},model:{value:(_vm.data.block_payment),callback:function ($$v) {_vm.$set(_vm.data, "block_payment", $$v)},expression:"data.block_payment"}})],1)],1)],1)],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","dark":"","small":"","loading":_vm.loading,"color":!_vm.id ? 'success' : 'info'},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s(!_vm.id ? 'mdi-plus' : 'mdi-pencil')}})],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(!_vm.id ? 'Crear' : 'Editar')}})])],1)],1)],1)],1),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }