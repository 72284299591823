<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                x-small
                v-on="on"
                class="mr-1"
                color="grey darken-3"
                :to="{
                  name: `contratantes.cpts`,
                  params: { id: contractor_id },
                }"
              >
                <v-icon v-text="'mdi-arrow-left'" />
              </v-btn>
            </template>
            <span v-text="'Atrás'" />
          </v-tooltip>
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="data_form" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-autocomplete
                      label="Procedimiento definitivo (CPT)"
                      v-model="data.cpt_id"
                      :items="cpts"
                      item-value="id"
                      :item-text="(v) => `${v.code} | ${v.cpt}`"
                      :loading="cpts_ldg"
                      :rules="rules.required"
                      dense
                    >
                      <template v-slot:selection="{ item }">
                        <div class="text-caption d-flex flex-column">
                          {{ `${item.code} | ${item.cpt}` }}
                        </div>
                      </template>
                      <template v-slot:item="{ item }">
                        <div class="text-caption d-flex flex-column">
                          {{ `${item.code} | ${item.cpt}` }}
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      dense
                      label="Observación"
                      v-model="data.observation"
                      rows="2"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Monto"
                      v-model="data.amount"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0"
                      :label="'Exclusión'"
                      v-model="data.block_payment"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  dark
                  small
                  v-on="on"
                  class="ml-1"
                  :loading="loading"
                  :color="!id ? 'success' : 'info'"
                  @click.prevent="handleSubmit"
                >
                  <v-icon v-text="!id ? 'mdi-plus' : 'mdi-pencil'" />
                </v-btn>
              </template>
              <span v-text="!id ? 'Crear' : 'Editar'" />
            </v-tooltip>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  toFormData,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    FaqDlg,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      loading: true,
      id: this.$route.params.id ? this.$route.params.id : null,
      contractor_id: this.$route.params.contractor_id,
      contractor: null,
      data: {
        id: null,
        active: true,
        amount: "",
        observation: "",
        block_payment: false,
        contractor_id: null,
        cpt_id: null,
      },
      rules: rules(),
      cpts: [],
      cpts_ldg: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },

  methods: {
    handleSubmit() {
      if (this.$refs.data_form.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${!this.id ? "creación" : "edición"} del registro?`
            )
          )
          .then((res) => {
            if (res.isConfirmed) {
              this.loading = true;

              Axios.post(
                `${URL_API}/contractors/cpts`,
                this.data,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                res.data.success
                  ? this.$router.push({
                      name: `contratantes.cpts`,
                      params: { id: this.contractor_id },
                    })
                  : console.log(res.data.message);
              });

              this.loading = false;
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    catalog("cpts", this.login.token).then((response) => {
      this.cpts = response;
      this.cpts_ldg = false;
    });

    Axios.get(
      URL_API + "/contractors/" + this.contractor_id,
      headersToken(this.login.token)
    ).then((resp) => {
      this.contractor = resp.data.data;

      this.data.contractor_id = this.contractor_id;

      if (this.id) {
        this.loading = true;
        Axios.get(
          `${URL_API}/contractors/cpts/${this.id}`,
          headersToken(this.login.token)
        ).then((response) => {
          this.data = response.data.data;
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    });
  },
};
</script>